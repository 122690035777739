.react-stacked-center-carousel {
  padding: 33px 0;
}

.card-card {
  transition: all 300ms ease;
  cursor: pointer;
  width: 150%;
  border-radius: 10px;
  height: 225px;
  position: relative;
}

.card-card:hover {
  transform: scale(1.05);
}

.react-stacked-center-carousel-slide-0 .card-card {
  cursor: default;
  height: 225px;
}

.react-stacked-center-carousel-slide-0 .card-card:hover {
  transform: none;
}

.fill {
  width: 100%;
  height: 100%;
}

.card-card .card-overlay {
  user-select: none;
  position: absolute;
  background-color: #00000099;
  transition: all 300ms ease;
  border-radius: 10px;
}

.card-card:hover .card-overlay {
  background-color: #0000002d
}

.react-stacked-center-carousel-slide-0 .card-overlay {
  background-color: #00000099 !important;
}

.card-card .cover-image {
  object-fit: cover;
}

.card-card .cover {
  position: absolute;
  transition: opacity 300ms ease;
}

.react-stacked-center-carousel-slide-0 .card-card .cover {
  transition: opacity 300ms ease, z-index 0ms 300ms;
}

.card-card .cover.on {
  opacity: 1;
  z-index: 1;
}

.card-card .cover.off {
  opacity: 0;
  z-index: -1;
}

.card-card .detail {
  display: flex;
}

.card-card .video {
  width: 20%;
}

.card-card .discription {
  display: flex;
  flex-direction: column;
  margin: 10px;
}
