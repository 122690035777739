.video-js.vjs-playing .vjs-tech,
.vjs-tech {
  pointer-events: none;
}

.player-section {
  height: 100%;
  width: 100%;
}

.video-container {
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
  width: 100%;
}

.video-js {
  background-color: var(--color-black);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

video.active-player {
  display: block;
}

.black-cover {
  background: var(--color-black);
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 1;
}

@media (max-width: 100px) {
  .player-section {
    height: 100%;
    padding: 0;
    width: 100%;
  }

  .video-container {
    border-radius: 0;
    height: auto;
    overflow: auto;
    padding: 0;
    position: static;
  }

  video {
    background-color: var(--color-black);
    border-radius: 0;
  }
}

.spinner {
  animation: rotate 1s linear infinite;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 20;
  fill: white;
  transform: translate(-50%, -50%) scale(1.5);
}

.video-timer-container {
  color: #fff;
  font-size: 15px;
}

.vjs-big-play-centered {
  height: 100%;
  width: 100%;
  position: relative;
  padding-top: 56.25% !important; /* 16:9 Aspect Ratio */
  border-radius: 1rem;
}

@media only screen and (max-width: 400px) {
  .vjs-big-play-centered {
    border-radius: unset;
  }
}
