:root {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 62.5% !important;
    --toastify-toast-min-height: 4rem !important;
}

.Toastify__toast-body {
    padding: 0 0 0 1rem !important;
}

* {
    scroll-behavior: smooth;
}

*:focus {
    outline: none !important;
}

body {
    padding: 0;
    margin: 0;
}

b {
    font-weight: 500;
}

/* 
 * This has been added to override popover
 * adding these properties
 * */
.Toastify__toast {
    font-size: 1.4rem !important;
    border-radius: 10px !important;
    margin: 2rem 1rem 0 1rem !important;
}

.Toastify__toast-container {
    bottom: 1rem !important;
}

iframe {
    color-scheme: normal;
}

a,
a:visited,
a:hover,
a:active {
    color: inherit;
    text-decoration: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hideScrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hideScrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

main {
    display: flex;
}

iframe {
    color-scheme: auto;
}

.fontBold {
    font-weight: 500 !important;
}

.fontGrey {
    color: #757575 !important;
}

.fontWhite {
    color: #fff !important;
}

.flex {
    display: flex;
}

.alignItemsCenter {
    align-items: center;
}

.alignSelfStart {
    align-self: start;
}

.overflow {
    overflow: hidden;
}

.topPositioned {
    top: 0 !important;
}

.topPositionedLiveCarousel {
    top: 22px !important;
}

.leftPositioned {
    left: 0 !important;
}

.rightPositioned {
    right: 0 !important;
}

.bottom30 {
    bottom: 30px !important;
}

.cursorPointer {
    cursor: pointer !important;
}

.cursorDefault {
    cursor: default !important;
}

.positionRelative {
    position: relative;
}

.absolutePositioned {
    position: absolute !important;
}

.zIndexOne {
    z-index: 1;
}

.zIndexTwo {
    z-index: 2;
}

.zIndexTen {
    z-index: 10;
}

.noLineHeight {
    line-height: 0 !important;
}

.flexOne {
    flex: 1 !important;
}

.topPositioned {
    top: 0 !important;
}

.bottomPositioned {
    bottom: 0 !important;
}

.squareAspectRatio {
    padding-top: 100% !important;
}

.rectangleAspectRatio {
    padding-top: 56.25% !important;
}

.justifyContentCenter {
    justify-content: center !important;
}

.leftSpacing {
    margin-left: 1rem !important;
}

.ml-none {
    margin-left: unset !important;
}

.capitalize {
    text-transform: capitalize !important;
}

.uppercase {
    text-transform: uppercase !important;
}

.textTransformNone {
    text-transform: none !important;
}

.fullWidth {
    width: 100% !important;
}

.topSpacing {
    margin-top: 1rem !important;
}

.topPadding {
    padding-top: 1rem !important;
}

#nprogress {
    pointer-events: none;
}

#nprogress .bar {
    background: #126bd5;

    position: fixed;
    z-index: 1531;
    top: 0;
    left: 0;
    width: 100%;
    height: 3px;
}

.MuiDrawer-paper {
    width: 20% !important;
}

/* Fancy blur effect */
#nprogress .peg {
    display: block;
    position: absolute;
    right: 0px;
    width: 100px;
    height: 100%;
    box-shadow: 0 0 10px #29d, 0 0 5px #29d;
    opacity: 1;

    -webkit-transform: rotate(3deg) translate(0px, -4px);
    -ms-transform: rotate(3deg) translate(0px, -4px);
    transform: rotate(3deg) translate(0px, -4px);
}

.toastr {
    min-height: 0px !important;
}

.rrt-success {
    background-color: #4caf50 !important;
}

.rrt-info {
    background-color: #2196f3 !important;
}

.rrt-error {
    background-color: #f44336 !important;
}

.toastr-icon {
    width: 25px !important;
    height: 25px !important;
}

.close-toastr {
    font-size: 15px !important;
}

.bottomSpacing {
    margin-bottom: 1rem !important;
}

.spaceBetween {
    justify-content: space-between !important;
}

.flexEnd {
    justify-content: flex-end !important;
}

.rightSpacing {
    margin-right: 1rem !important;
}

.spaceEvenly {
    justify-content: space-evenly !important;
}

.fontNormal {
    font-weight: normal !important;
}

.anchorText {
    text-decoration: none !important;
    color: inherit !important;
}

.parentHeight {
    height: 100% !important;
}

.parentWidth {
    width: 100% !important;
}

.parentHeightWidth {
    height: 100% !important;
    width: 100% !important;
}

.flexWrap {
    flex-wrap: wrap !important;
}

.noPointerEvents {
    pointer-events: none !important;
    opacity: 0.4 !important;
}

.noDisplay {
    display: none !important;
}

.whiteText {
    color: #fff !important;
}

.primaryColor {
    color: #126bd5 !important;
}

.textOverflowEllipsis {
    overflow: hidden !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
}

.textAlignCenter {
    text-align: center !important;
}

.textAlignRight {
    text-align: right !important;
}

.textAlignLeft {
    text-align: left !important;
}

.flexDirectionColumn {
    flex-direction: column !important;
}

.staticContainer {
    padding: 10px;
    margin-bottom: 48px;
}

.bold {
    font-weight: bold;
}

.underline {
    text-decoration: underline;
}

.smallFont {
    font-size: 13px;
}

.MuiAppBar-root {
    z-index: 0 !important;
}

.MuiMenu-paper {
    box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.12) !important;
}

.linkify {
    color: #126bd5 !important;
}

@keyframes marquee {
    from {
        margin-left: 120%;
    }

    to {
        margin-left: -20%;
    }
}

.colorWhite {
    color: white !important;
}

.colorGrey {
    color: #757575 !important;
}

.colorBlack {
    color: black !important;
}

.marquee-container {
    overflow: hidden;
}

.marquee {
    min-width: 100%;
    animation: marquee 15s linear infinite;
}

.marquee:hover {
    animation-play-state: paused;
}

.textCenter {
    text-align: center;
}

.dropzone {
    border: 2px dashed rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    padding: 50px 0;
    text-align: center;
    text-transform: uppercase;
    color: red;
}

.dropzone.active {
    border-color: #fff;
}

.file {
    text-decoration: none;
    color: rgba(255, 255, 255, 0.8);
    display: block;
    background-color: #41415d;
    border-radius: 10px;
    margin-top: 20px;
    padding: 10px;
    position: relative;
    overflow: hidden;
}

.file .progress {
    background-color: rgba(80, 80, 120, 0.8);
    position: absolute;
    inset: 0;
    width: 0;
    padding-top: 10px;
    text-align: center;
}

.file .progress.done {
    top: 100px;
}

.hideScrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */

.hideScrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.wordpress-blog-image img {
    width: 100%;
    height: fit-content;
}

/* .rooter-typography {
    font-size: calc(0.75rem + 0.5vw);
} */

/* @media (max-width: 600px) {
    .rooter-typography {
        font-size: 1.6rem;
    }
} */
.animationProBorder:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    height: 140px;
    width: 140px;
    background-image: conic-gradient(#00DBDE00 10%, #00dbde 20%, #6a8fef 30%, #6A8FEF00 40%, #6A8FEF00 60%, #00dbde 70%, #6a8fef 80%, #00DBDE00 90%);
    z-index: 0;
    transform: translate(-50%, -50%);
    animation: rotateProChip 6s linear infinite;
}

@keyframes rotateProChip {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }

    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

.not-found-page {
    background-color: #121212;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-size: 20px;
    margin: 0;
}

.link {
    color: blue;
    text-decoration: underline;
}