.sessionStatsContainer {
    display: flex;
    align-items: center;
}

.sessionStatsContainerHome {
    display: flex;
    align-items: center;
    transform: scale(0.9);
}

.statContainer {
    position: absolute;
    left: 10px;
    top: 0;
    z-index: 50;
}

.liveTagText {
    font-weight: bold;
    color: #fff;
    line-height: 1.5;
    font-size: 1.5rem;
}

.eyeIcon {
    height: 15px;
    width: 15px;
}

.giftIcon {
    height: 2rem;
    width: 2rem;
    transform: scale(0.7);
    margin: -2.5px 2px 0 0;
}

.liveTagContainer {
    background: linear-gradient(to right, #ff0956, #ff5310);
    padding: 0.1rem 0.5rem;
    border-radius: 3px;
    display: flex;
    margin: 1rem 0;
    z-index: 1;
    align-items: center;
}

.viewTagContainer {
    background: rgb(0, 0, 0, 0.5);
    padding: 0.1rem 0.5rem;
    border-radius: 3px;
    display: flex;
    margin: 1rem 0;
    align-items: center;
}

.viewTagText {
    font-weight: bold;
    color: #fff;
    line-height: 1.5;
    margin-left: 0.3rem;
    font-size: calc(0.55rem + 0.5vw);
}

.liveTimeContainer {
    background: rgb(0, 0, 0, 0.5);
    padding: 0.1rem 0.5rem;
    border-radius: 0 3px 3px 0;
    display: flex;
    margin-left: -2px;
    align-items: center;
    margin-right: 1rem;
}

.liveTimeText {
    font-weight: bold;
    color: #fff;
    line-height: 1.5;
    margin-left: 0.3rem;
    font-size: calc(0.55rem + 0.5vw);
}

@media only screen and (max-width: 400px) {
    .eyeIcon {
        height: 12px;
        width: 12px;
    }

    .liveTagContainer {
        padding: 0.1rem 0.75rem;
    }

    .viewTagText {
        font-size: 1rem;
    }
}
