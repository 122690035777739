.pipButtonsContainer {
  height: 100%;
  width: 100%;
  background: transparent;
  z-index: 2;
  border-radius: 5px;
}

.icon {
  height: 22px;
  width: 22px;
}

.closeIconContainer {
  right: 0;
  padding: 0.5rem;
  border-radius: 5px;
}

.interactionButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 200px;
  padding: 0.2rem;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
}

.margin {
  margin: 1rem 2rem;
}
